<template>
  <section v-loading='isLoading' class='product-page'>
    <div>
      <div class='page-header xa-cell'>
        <div class='xa-flex'>
          <el-link
            type='primary'
            class='xa-txt-blue'
            :href='manageUrl'
            v-show='manageUrl'
            target='_blank'
          >商品管理
          </el-link
          >
        </div>
        <div class='xa-cell ' style='margin:0 8px'>
          <el-cascader
            v-model='query.selectedOptions'
            :options='filterOptions'
            placeholder='产品分类'
            size='small'
            @change='onSearchQuery'
            :props='{ checkStrictly: true }'
          ></el-cascader>
        </div>
        <div class='search-box'>
          <el-input
            placeholder='搜索商品名称或编码'
            v-on:keyup.enter.native='onSearchQuery(true)'
            v-model='query.keyword'
            size='small'
          >
            <i
              @click='onSearchQuery(true)'
              slot='prefix'
              :class="{ 'xa-color-info': !!query.keyword }"
              class='el-input__icon el-icon-search'
            ></i>
            <el-button
              slot='append'
              icon='el-icon-search'
              @click='onSearchQuery'
            ></el-button>
          </el-input>
        </div>
        <el-button
          style='margin-left:8px;'
          icon='el-icon-remove-outline'
          size='small'
          @click='onResetQuery'
        >重置
        </el-button
        >
      </div>
      <div class='page-table'>
        <el-table
          ref='multipleTable'
          height='400'
          :data='tableData'
          tooltip-effect='dark'
        >
          <el-table-column label='' width='50'>
            <template #header v-if="selectType === 'checkbox'">
              <el-checkbox
                :indeterminate='isIndeterminate'
                v-model='selectAll'
                @change='selectAllChange'
              ></el-checkbox>
            </template>
            <template slot-scope='scope'>
              <el-checkbox
                v-model='scope.row._checked'
                @input='onCheckItem(scope.row)'
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column label='产品'>
            <template slot-scope='scope'>
              <div class='xa-cell'>
                <div
                  @click='onPriview(scope.row.first_pic)'
                  class='img-wrap'
                  :style="{
                    backgroundImage: 'url(' + scope.row.first_pic + ')',
                  }"
                ></div>
                <div>
                  <div>{{ scope.row.title }}</div>
                  <div class='xa-txt-red'>￥{{ scope.row.price }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop='category_name' label='分类' width='200' />
        </el-table>
      </div>
      <div class='page-pagination xa-cell'>
        <div class='xa-flex'></div>
        <el-pagination
          @size-change='handleSizeChange'
          @current-change='handleCurrentChange'
          :current-page='query.p'
          :page-sizes='[10, 20, 50, 100, 200, 500]'
          :page-size='query.psize'
          layout='total, sizes, prev, pager, next'
          :total='totalDataCount'
        />
      </div>
    </div>
    <div class='page-btns xa-cell'>
      <div
        v-if='multipleSelection.length'
        class='xa-cursor'
        style='margin-right:16px;'
        @click='isShowCheckedProduct'
      >
        已选:商品
        <span class='xa-txt-blue'>({{ multipleSelection.length }})</span>
      </div>
      <el-button size='small' @click='onClose'>取消</el-button>
      <el-button
        :disabled='multipleSelection.length == 0'
        type='primary'
        size='small'
        @click='onSubimt'
      >确定
      </el-button
      >
    </div>
  </section>
</template>
<script>
import {
  getCategoryListOptions,
  getLimiProductList,
  getManageUrl
} from '@target/apis/coupon'

export default {
  props: {
    params: {
      type: Object,
      default() {
        return {}
      }
    },
    selectType: {
      type: String,
      default: 'checkbox'
    }
  },
  data() {
    return {
      manageUrl: '',
      isLoading: true,
      totalDataCount: 0,
      query: {
        p: 1,
        psize: 10,
        keyword: '',
        selectedOptions: []
      },
      filterOptions: [],
      tableData: [],
      multipleSelection: [],
      selectAll: false,
      checkMap: {},
      isIndeterminate: false
    }
  },
  methods: {
    onSubimt() {
      if (this.multipleSelection.length) {
        this.$emit('submit', this.multipleSelection)
        // this.multipleSelection = []
        // this.tableData.forEach((item) => (item._checked = false))
      }
    },
    onPriview(img) {
      this.$preViewImg({ isShow: true, imgSrc: img, imgList: [img] })
    },
    onClose() {
      this.$emit('close')
    },
    handleSizeChange(val) {
      this.query.psize = val
      this.onSearchQuery()
    },
    handleCurrentChange(val) {
      this.query.p = val
      this.onSearchQuery()
    },
    onSearchQuery(isFormSearch) {
      if (isFormSearch) {
        this.query.p = 1
      }
      const query = {
        p: this.query.p,
        psize: this.query.psize,
        keyword: this.query.keyword,
        category_guid: this.query.selectedOptions.length
          ? this.query.selectedOptions[this.query.selectedOptions.length - 1]
          : ''
      }
      this.queryData(query)
    },
    onResetQuery() {
      this.query.keyword = ''
      this.query.selectedOptions = []
      this.onSearchQuery()
    },
    deleteItem(item) {
      const index = this.multipleSelection.findIndex(
        (has) => has.guid === item.guid
      )
      this.multipleSelection.splice(index, 1)
    },
    onCheckItem(item) {
      if (this.selectType === 'radio') {
        const checked = this.multipleSelection.pop()
        checked && (checked._checked = false)
      } else { // 多选时需要考虑全选按钮的状态
        //this.selectAll = this.tableData.every(item => item._checked)
        this.setCheckboxStatus(this.tableData)
      }
      if (item._checked) {
        this.multipleSelection.push(item)
      } else {
        this.deleteItem(item)
      }
    },
    selectAllChange(value) {
      // eslint-disable-next-line no-console
      this.tableData.forEach(item => {
        item._checked = value
      })
      // 全选选中时
      this.tableData.map(item => {
        const exist = this.multipleSelection.find(
          (has) => has.guid === item.guid
        )
        if ((value && exist) || (!value && !exist)) {
          // 选中并已存在 或者 未选中并未存在
          return ''
        } else if (value && !exist) {
          // 选中且未存在
          this.multipleSelection.push(item)
        } else if (!value && exist) {
          // 未选中 且已存在 （去除）
          this.deleteItem(item)
        }
      })

      // this.multipleSelection = value ? this.tableData.map(item => item) : []
    },

    // 处理表格中是否选中
    handleHasSelectedData(items) {
      return items.map((add) => {
        add._checked = false
        const target = this.multipleSelection.find(
          (has) => has.guid === add.guid
        )
        return target || add
      })
    },

    // 设置表头复选框状态
    setCheckboxStatus(tableData) {
      let selectCount = 0
      tableData.map((item) => {
        if (item._checked) {
          selectCount++
        }
      })
      if (tableData.length > 0 && selectCount === tableData.length) {
        // 设置全选
        this.selectAll = true
        this.isIndeterminate = false
      } else if (selectCount === 0) {
        //设置不选
        this.selectAll = false
        this.isIndeterminate = false
      } else {
        // 设置半选
        this.isIndeterminate = true
      }
    },

    // 表格内容查询
    async queryData(query = {}) {
      this.isLoading = true
      const searchData = await getLimiProductList({
        ...this.params,
        ...query
      })
      this.tableData = this.handleHasSelectedData(searchData.data)
      this.setCheckboxStatus(this.tableData)
      // this.tableData = searchData.data.map(item => ({...item, _checked: false}))
      // this.selectAll = false
      // this.multipleSelection = []
      this.totalDataCount = parseInt(searchData.recordCount)
      this.$nextTick(() => {
        this.isLoading = false
      })
    },

    // 显示已选商品的表格
    isShowCheckedProduct() {
      if (this.multipleSelection.length) {
        this.$emit('show-product', this.multipleSelection)
      }
    },

    updateTableCheck(product) { // 更新选中的数据
      // eslint-disable-next-line no-console
      let checkMap = product.reduce((result, item) => {
        result[item.guid] = true
        return result
      }, {})
      this.checkMap = checkMap
      this.multipleSelection = product
      // this.multipleSelection = this.multipleSelection.filter(item => checkMap[item.guid])
      this.tableData.forEach(item => {
        // eslint-disable-next-line no-console
        console.log(checkMap[item.guid])
        item._checked = !!checkMap[item.guid]
      })
      this.selectAll = this.tableData.every(item => checkMap[item.guid])
    }
  },
  async created() {
    try {
      const data = await getCategoryListOptions(this.params)
      this.filterOptions = data
      this.queryData(this.query)
      this.manageUrl = getManageUrl(this.params)
    } finally {
      this.isLoading = false
    }
  }
}
</script>
<style lang='scss' scoped>
.img-wrap {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border: 1px dotted #ccc;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-right: 4px;
  cursor: pointer;
}

.page-pagination {
  padding: 8px 0;
}

.page-btns {
  border-top: 1px solid #e4e4e4;
  padding: 8px 0;
  justify-content: flex-end;
}
</style>
